import React from 'react';
import './App.css'; // Make sure this line is here to include your styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <div className="App">
      <header>
        <h1>My Portfolio</h1>
      </header>
      <section id="about">
        <h2>About Me</h2>
        <p>Hi! I am Kevin Santhosh, a Computer Science student with a specialization in Artificial Intelligence and Machine Learning. From a very young age I was fascinated by computers because of my love for Video Games. I enjoy learning new stuff and making games in my free time. Since the past few years there has been a rapid growth in the development of AI and it does not seem to be slowing down anytime soon. It will continue to be a game changer and I believe that the best is yet to come. Thats why I have a keen interest in Generative AI which I think is the next best technological development after the transistor.</p>
      </section>
      <section id="skills">
        <h2>My Skills</h2>
        <ul>
          <li>Python</li>
          <li>C/C++</li>
          <li>PyTorch</li>
          <li>Machine Learning</li>
          <li>Computer Vision</li>
          <li>Generative AI with Deep Learning</li>
        </ul>
      </section>
      <section id="projects">
        <h2>Projects</h2>
        <ul>
          <li>
            <span>Mountain Car Problem Solution with NEAT Algorithm</span>
            <a href="https://github.com/HavokMaster/mountaincar-NEAT" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} style={{ marginLeft: '5px', color: 'white' }} />
            </a>
            <p>A solution for the infamous Mountain Car problem that automatically learns to reach the top of a mountain via the NeuroEvolution of Augmenting Topologies algorithm.</p>
          </li>
          <li>
            <span>Master's Image Loader</span>
            <a href="https://github.com/HavokMaster/MIL" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} style={{ marginLeft: '5px', color: 'white' }} />
            </a>
            <p>A custom python script to load images for training a ML or DL model. It can automatically load images and assign them class labels all you need to do is place images of each class in a separate folder.</p>
          </li>
          <li>
            <span>LUNA - A multimodal AI Assistant</span>
            <a href="https://github.com/HavokMaster/Luna" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} style={{ marginLeft: '5px', color: 'white' }} />
            </a>
            <p>LUNA is a AI virtual assistant that can interact with the user in Natural Language. Luna can help the user with various tasks and help automate various repetitive processes and provide ease of access to the user.</p>
          </li>
          <li>
            <span>TombAimer - An aim assist program for the game Tomb Raider Anniversary</span>
            <a href="https://github.com/HavokMaster/tombaimer-AI" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} style={{ marginLeft: '5px', color: 'white' }} />
            </a>
            <p>This aim assist program utilizes Computer Vision by leveraging the FastCNN model from TensorFlow Hub to detect hostiles and automatically shoot them in the video game Tomb Raider: Anniversary</p>
          </li>
        </ul>
      </section>
      <section id="contact">
        <h2 className='email'>Contact</h2>
        <p>Reach out via email at: <span className='email'>kevinsanthoshpaul@gmail.com</span></p>
        <div className="social-links">
          <a href="https://linkedin.com/in/kevinsanthosh" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '2rem' }} /> {/* Increase the font size */}
          </a>
          <a href="https://github.com/HavokMaster" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} style={{ fontSize: '2rem' }} /> {/* Increase the font size */}
          </a>
          <a href="https://youtube.com/@thehavokmaster" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} style={{ fontSize: '2rem' }} /> {/* Increase the font size */}
          </a>
        </div>
      </section>
    </div>
  );
}

export default App;
